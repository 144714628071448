<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl mt-5 font-bold text-blue-800">Admin</h1>
      <div class="flex my-3">
        <div class="">
          <button
            class="bg-gray-800 py-2 px-3 rounded-md text-white"
            @click="
              $router.push({
                name: 'Admin',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="flex flex-wrap xl:-mx-4 pb-5">
        <div class="w-full xl:my-4 xl:px-4">
          <h4 class="text-blue-900 text-lg font-semibold">Create Admin</h4>
          <p class="text-green-600" v-if="successMssg">
            Admin was created successful
          </p>
        </div>

        <div class="w-2/3 xl:my-4 xl:px-4">
          <div
            class="w-full p-5 pb-20 border-2 border-gray-100 bg-white rounded-lg"
          >
            <div>
              <form @submit.prevent="promptModal = true">
                <div class="flex flex-wrap xl:-mx-2">
                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">
                      First name <span class="text-red-600">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Enter First Name"
                        id=""
                        v-model="firstName"
                        required
                      />
                    </p>
                  </div>

                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">
                      Last name <span class="text-red-600">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <input
                        type="text"
                        class="inline-block p-3 ring-1 w-full outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Enter Last Name"
                        id=""
                        v-model="lastName"
                        required
                      />
                    </p>
                  </div>
                </div>
                <div class="flex flex-wrap xl:-mx-2">
                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">
                      Email <span class="text-red-600">*</span>
                    </p>
                    <p class="text-sm w-full font-semibold">
                      <input
                        type="email"
                        class="inline-block w-full p-3 ring-1 outline-none ring-gray-300 rounded-sm text-sm"
                        name=""
                        placeholder="Enter Email"
                        id=""
                        v-model="email"
                        required
                      />
                    </p>
                  </div>

                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">
                      Assign Role <span class="text-red-600">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <select
                        class="inline-block p-3 w-full drop-shadow-2xl ring-1 ring-gray-300 bg-white outline-none rounded-sm text-sm"
                        v-model="adminUserType"
                        required
                      >
                        <option value="" disabled>Choose Admin type</option>
                        <option value="ADMIN">Admin</option>
                        <option value="SUBADMIN">Sub-Admin</option>
                      </select>
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-wrap xl:-mx-2"
                  v-if="adminUserType == 'SUBADMIN'"
                >
                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2">
                    <!-- Column Content -->
                    <p class="text-xs font-bold mb-3">
                      Role <span class="text-red-600">*</span>
                    </p>
                    <p class="text-sm font-semibold">
                      <select
                        class="inline-block p-3 w-full drop-shadow-2xl ring-1 ring-gray-300 bg-white outline-none rounded-sm text-sm"
                        v-model="assignRole"
                        required
                      >
                        <option value="" disabled>Select Role</option>
                        <option
                          :value="dept"
                          v-for="(dept, i) in department"
                          :key="i"
                        >
                          {{ dept }}
                        </option>
                      </select>
                    </p>
                  </div>

                  <div class="w-full xl:my-2 xl:px-2 xl:w-1/2"></div>
                </div>
                <div class="my-5 w-full">
                  <button
                    class="py-3 px-6 bg-blue-800 float-right rounded-md font-semibold text-white text-sm"
                    type="submit"
                  >
                    Create Admin
                  </button>
                  <button
                    class="py-3 px-6 bg-transparent float-right rounded-md font-semibold text-red-600 text-sm"
                    @click="resetForm"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="promptModal"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <div v-if="!errorMssg && !successMssg" class="block">
            <h4 class="text-center font-semibold">Confirm Admin Creation</h4>
            <p class="text-xs">
              Confirm {{ assignRole }} account creation for
              {{ firstName + " " + lastName }}<br />
              {{ email }}
            </p>
            <button
              @click="createAdmin"
              class="block uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded px-10 mt-5"
              style="width: inherit"
            >
              Confirm
            </button>
            <button
              @click="closePromptModal"
              class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 rounded"
              style="width: inherit"
            >
              Close
            </button>
          </div>
          <div v-if="successMssg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-check2-circle mx-auto my-2 text-green-600"
              viewBox="0 0 16 16"
            >
              <path
                d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
              />
              <path
                d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
              />
            </svg>
            <p class="text-sm">
              {{ assignRole }} account has been successfully created and account
              information has been sent to
              <span class="font-semibold">{{ email }}</span>
            </p>
            <button
              @click="$router.push({ name: 'Admin' })"
              class="uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 px-4 rounded"
              style="width: inherit"
            >
              Close
            </button>
          </div>
          <div v-if="errorMssg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              class="bi bi-exclamation-triangle mx-auto my-2 text-red-600"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
              />
              <path
                d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
              />
            </svg>
            <p class="text-sm my-5" v-if="statusCode">
              A/An {{ assignRole }} account with this email (<span
                class="font-semibold"
                >{{ email }}</span
              >) already exists
            </p>
            <p v-else>
              {{ mssg }}
            </p>
            <button
              @click="closePromptModal"
              class="mt-2 uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 rounded px-4"
              style="width: inherit"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      firstName: "",
      lastName: "",
      email: "",
      selectLocation: "",
      assignRole: "",
      errorMssg: false,
      mssg: "",
      successMssg: false,
      promptModal: false,
      adminUserType: "",
      statusCode: false,
      department: [
        "Marketing",
        "Credit Operations",
        "Corporate Sales",
        "Product Operations",
        "Chargeback Team",
        "Platform Monitoring Team",
        "Fraud Desk",
        "Settlement & Reconcillation",
        "Commercial Operations",
      ],
    };
  },
  computed: {
    ...mapGetters(["GET_LOCATIONS"]),
    filterLocations() {
      return this.GET_LOCATIONS.data;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  methods: {
    async createAdmin() {
      let payload = {
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        email: this.email.trim(),
        adminUserType: this.adminUserType,
        Department: this.assignRole,
      };
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("CREATE_ADMIN", payload);
        this.isLoading = false;
        if (res.status) {
          this.successMssg = true;
          // this.firstName = "";
          // this.lastName = "";
          // this.email = "";
          // this.selectLocation = "";
          // this.assignRole = "";
        } else {
          if (res.code == 400) {
            if (res.message == "this email already exists") {
              this.statusCode = true;
            } else {
              this.statusCode = false;
              this.mssg = res.message;
            }
          }
          this.successMssg = false;
          this.errorMssg = true;
        }
      } catch (error) {}
    },
    closePromptModal() {
      this.errorMssg = false;
      this.successMssg = false;
      this.promptModal = false;
      // this.$router.push({ name: "Admin" });
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.selectLocation = "";
      this.assignRole = "";
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
